<template>
  <div class="flex items-center space-x-4 song-info-wrap">
    <div class="flex-shrink-0 relative">
      <router-link :uk-tooltip="'title: ' + song.title" v-if="song.id" :title="song.title" class="text-lg font-semibold" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: song.prefix}}">
        <img :alt="song.title" :src="getThumbnailOfSong(song)" class="w-10 h-10 inset-0 rounded-md object-cover">
      </router-link>
      <img v-if="!song.id" :alt="song.title" src="https://static.bcdcnt.net/assets/images/group/group-3.jpg" class="w-10 h-10 inset-0 rounded-md object-cover">
    </div>
    <div class="flex-1 hidden sm:block">
      <router-link :uk-tooltip="'title: ' + song.title" v-if="song.id" :title="song.title" class="font-semibold line-clamp-1" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: song.prefix}}">
        {{ song.title }}
      </router-link>
      <div v-if="!song.id" class="font-semibold line-clamp-1">{{ song.title }}</div>
      <div class="text-sm text-gray-500 mt-0.5 people-in-song line-clamp-1" v-if="song.artists && song.artists.data">
        <PeopleOfSong :people="song.artists.data" type="artist"/>
      </div>
      <div class="text-sm text-gray-500 mt-0.5 people-in-song line-clamp-1" v-if="song.users && song.users.data">
        <user-of-song :people="song.users.data"/>
      </div>
    </div>
  </div>
</template>

<script>
import PeopleOfSong from "./song/PeopleOfSong";
import UserOfSong from "./song/UserOfSong";
import {getThumbnailOfSong} from "../core/services/utils.service";

export default {
  name: "SongItemPlayer",
  props: {
    song: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  },
  methods: {
    getThumbnailOfSong(song) {
      return getThumbnailOfSong(song);
    }
  },
  components: {
    PeopleOfSong, UserOfSong
  }
}
</script>
